<header class="headPos header fontFam">
      <div class="container wrapper img-margin-btm h56">
        <div class="padTop12">
          <img src="assets/images/Pictorial.svg" alt="">
          <span class="pl24 fs14bold">Swift</span>
  
          <span class="border-lft"></span>
            <span class="allpad16 spanOutline fsize14 txtblack" [routerLink]="['/search']">Messages</span>
            <span class="allpad16 spanOutline fs14-56585A" [routerLink]="['/recon']">Reconciliation </span>
            <span class="allpad16 spanOutline fs14-56585A" [routerLink]="['/print']">Print</span>
        </div>
  
    <div class="spanImg marRight8-16">
      <img src="assets/images/Image.svg" alt=""> &nbsp; 
      <label class="fsize14 txt111 padding-0-16px">Ethan Matthew Hunt</label>
      <img src="/assets/images/Icon.svg" alt="">
    </div>
  </div>
  
  </header>
  