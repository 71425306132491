import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { FocusMonitor } from '@angular/cdk/a11y';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  ShowHeader: boolean = false;
  ShowSidenav: boolean = false;
  cdkShow: boolean = false;
  nextPosition: number = 0;
  isMenuOpen: boolean = false;
  isExpOpen: boolean = false;
  dateModel: Date;
  selected = 'option';

  constructor(router: Router, private focusMonitor:FocusMonitor) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (
          (event['url'] == '/auth') || (event['url'] == '') || (event['url'] == '/') 
          || (event['url'] == '/admin') || (event['url'] == '/admin/userdetails')
          || (event['url'] == '/admin/organisation') || (event['url'] == '/organisation')
        ) {
          this.ShowHeader = false;
        } else {
          this.ShowHeader = true;
        };
      }
    });
 
  }


  ngOnInit() {
}
}
