import { Component } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'swift';
  showHeader: boolean = false;
  showFooter: boolean = false;
  showSidenav: boolean = false;
  fieldDashboard: boolean = false;
  fieldMsg: boolean = true;
  fieldRecon: boolean = true;
  fieldProfile: boolean = true;
  fieldAdmin: boolean = true;
  fieldPrint: boolean = true;
  router: any;
  imgg: any;
  images: any;
  list : any[];

  constructor(router: Router,) {
    this.list = 
      [
        {name :'India',checked : false},
        {name :'US',checked : false},
        {name :'China',checked : false},
        {name :'France',checked : false}
      ],


    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (
          (event['url'] == '/auth') || (event['url'] == '') || (event['url'] == '/') || (event['url'] == '/admin') 
        ) {
          this.showHeader = false;
        } else {
          this.showHeader = true;
        };
        if (
          (event['url'] == '/auth') || (event['url'] == '') || (event['url'] == '/')
        ) {
          this.showHeader = false;
          this.showSidenav = false;
        } else {
          this.showHeader = true;
          this.showSidenav = true;
        }
      }
    });

    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (
           (event['url'] == '/instances') || (event['url'] == '/intervention') 
          || (event['url'] == '/appendix') || (event['url'] == '/messagedetails') 
        ) {
          this.showFooter = true; 
        } else  if (
          (event['url'] == '/advancedsearch') || (event['url'] == '/profile') || (event['url'] == '/print') 
          || (event['url'] == '/recon')  || (event['url'] == '/messages')
        ){
          this.showFooter = false;
        } else {
          this.showFooter = false;
        };
      }
    });
  }

  ngOnInit() {

}

  onDeactivate() {
    window.scrollTo(0, 0);
  }

  shareCheckedList(item:any[]){
    console.log(item);
  }
  shareIndividualCheckedList(item:{}){
    console.log(item);
  }


}
